import React, { useEffect } from 'react'
import styled from "styled-components"

const FacebookGrid = styled.div`
  margin:0;
`
const FacebookWidget = () =>{
     useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://cdn.trustindex.io/loader-feed.js?86355bc36420288e06469bd2208";
        script.async = true;
        script.defer = true;
        document.getElementById("facebook-script").appendChild(script)
     },[])
	return(
    <FacebookGrid>
      <div id="facebook-script"></div>
    </FacebookGrid>
	)
}

export default FacebookWidget